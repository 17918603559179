import React, { useEffect, useMemo, useState } from 'react'
import { AppState } from '../../reducers/rootReducer'
import { getRecordsIfNeeded } from '../../actions/records'
import { connect } from 'react-redux'
import { IRecord, ITour } from '../../interfaces'
import { RouteComponentProps, withRouter } from 'react-router'
import { Box, Flex, Heading, Text } from 'rebass'
import { Link } from 'react-router-dom'
import ImageCarousel from '../common/ImageCarousel'
import { getToursIfNeeded } from '../../actions/tours'
import moment from 'moment'
import copy from 'clipboard-copy'

interface IRecordProps extends RouteComponentProps<{ slug: string }> {
  isLoading: boolean
  getRecords: () => void
  recordsById: Record<string, IRecord>
  recordsBySlug: Record<string, IRecord>
  allTours: { [Key: string]: ITour }
  getToursIfNeeded: () => void
}
const Record: React.FC<IRecordProps> = ({
  allTours,
  isLoading,
  getRecords,
  recordsById,
  recordsBySlug,
  match: {
    params: { slug },
  },
  getToursIfNeeded: getTours,
}) => {
  const [copySuccess, setCopySuccess] = useState<boolean | undefined>(undefined)
  useEffect(() => {
    // This will get records only if we don't currently have them or if the
    // cache is invalidated.
    getRecords()
  }, [getRecords])

  useEffect(() => {
    getTours()
  }, [getTours])

  const record: IRecord | undefined = recordsBySlug[slug]

  const tourIds = record ? record.Tours : []

  const toursArray = useMemo(() => {
    return Object.values(allTours)
  }, [allTours])

  const formattedTours = useMemo(() => {
    return toursArray.reduce<{ id: string; title: string; slug: string }[]>(
      (acc, tour) => {
        if (tourIds.includes(tour.id)) {
          acc.push({
            id: tour.id,
            title: tour.title,
            slug: tour.slug,
          })
        }

        return acc
      },
      []
    )
  }, [tourIds, toursArray])

  if (isLoading) {
    return (
      <Box mx={[3, 3, 6]} my={[3, 3, 4]}>
        Loading...
      </Box>
    )
  }

  const handleCopyClick = () => {
    const date = moment().format('LL')
    const url = window.location
    const textToCopy = `${record.Creator}, "${record.Title}", Nashville Sites, accessed ${date}, ${url}`

    copy(textToCopy)
      .then(() => {
        setCopySuccess(true)
      })
      .catch(() => {
        setCopySuccess(false)
      })
  }

  return (
    <Box px={[3, 3, 6]} py={[3, 3, 4]}>
      <Flex
        mb={4}
        justifyContent="flex-start"
        alignItems="center"
        flexWrap="wrap"
      >
        <Heading fontSize={[4]} mr={3}>
          Full Record &amp; Citation
        </Heading>

        <Flex alignItems="center">
          <Box
            as="span"
            onClick={handleCopyClick}
            mr={2}
            sx={{
              cursor: 'pointer',
              textDecoration: 'underline',
              color: 'blue',
            }}
          >
            Copy citation
          </Box>

          {copySuccess !== undefined && (
            <Box
              fontSize={1}
              sx={{
                fontStyle: 'italic',
                color: copySuccess === false ? 'red' : 'inherit',
              }}
            >
              {copySuccess ? 'Copied to clipboard' : 'Copy error'}
            </Box>
          )}
        </Flex>
      </Flex>

      {record !== undefined ? (
        <>
          {record.Title && (
            <>
              <Box color="#666" mb={2} style={{ fontWeight: 'bold' }}>
                Title
              </Box>
              <Box mb={3}>{record.Title}</Box>
            </>
          )}

          {record.images.length > 0 && (
            <>
              <Box color="#666" mb={2} style={{ fontWeight: 'bold' }}>
                Photos
              </Box>
              <Flex flexWrap="wrap" mb={3}>
                <Box width={[1, 0.5, 0.25]} height={300}>
                  <ImageCarousel images={record.images} />
                </Box>
              </Flex>
            </>
          )}

          {formattedTours.length > 0 && (
            <>
              <Box color="#666" mb={2} style={{ fontWeight: 'bold' }}>
                Tours
              </Box>
              <Box mb={3}>
                {formattedTours.map(({ id, slug, title }) => (
                  <Box key={id} mb={1}>
                    <Link to={`/tours/${slug}`}>{title}</Link>
                  </Box>
                ))}
              </Box>
            </>
          )}

          {record.Address && (
            <>
              <Box color="#666" mb={2} style={{ fontWeight: 'bold' }}>
                Address
              </Box>
              <Box mb={3}>{record.Address}</Box>
            </>
          )}

          {record.Coordinates && (
            <>
              <Box color="#666" mb={2} style={{ fontWeight: 'bold' }}>
                Coordinates
              </Box>
              <Box mb={3}>
                Latitude: {record.Coordinates.lat} <br /> Longitude:{' '}
                {record.Coordinates.lng}
              </Box>
            </>
          )}

          {record.Description && (
            <>
              <Box color="#666" mb={2} style={{ fontWeight: 'bold' }}>
                Description
              </Box>
              <Box mb={3}>{record.Description}</Box>
            </>
          )}

          {record.Source.length > 0 && (
            <>
              <Box color="#666" mb={2} style={{ fontWeight: 'bold' }}>
                Source
              </Box>
              <Box mb={3}>{record.Source.join(', ')}</Box>
            </>
          )}

          {record.Contributor.length > 0 && (
            <>
              <Box color="#666" mb={2} style={{ fontWeight: 'bold' }}>
                Contributor
              </Box>
              <Box mb={3}>{record.Contributor.join(', ')}</Box>
            </>
          )}

          {(record.RelationLink || record.RelationText) && (
            <>
              <Box color="#666" mb={2} style={{ fontWeight: 'bold' }}>
                Relation
              </Box>
              <Box mb={3}>
                {record.RelationText && record.RelationLink && (
                  <a
                    href={record.RelationLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {record.RelationText}
                  </a>
                )}
                {record.RelationText && !record.RelationLink && (
                  <Text>{record.RelationText}</Text>
                )}
                {record.RelationLink && !record.RelationText && (
                  <a
                    href={record.RelationLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {record.RelationLink}
                  </a>
                )}
              </Box>
            </>
          )}

          {record.Type && (
            <>
              <Box color="#666" mb={2} style={{ fontWeight: 'bold' }}>
                Type
              </Box>
              <Box mb={3}>{record.Type}</Box>
            </>
          )}

          {record.Coverage && (
            <>
              <Box color="#666" mb={2} style={{ fontWeight: 'bold' }}>
                Coverage
              </Box>
              <Box mb={3}>{record.Coverage}</Box>
            </>
          )}

          {record.Rights && (
            <>
              <Box color="#666" mb={2} style={{ fontWeight: 'bold' }}>
                Rights
              </Box>
              <Box mb={3}>{record.Rights}</Box>
            </>
          )}

          {record.Subject.length > 0 && (
            <>
              <Box color="#666" mb={2} style={{ fontWeight: 'bold' }}>
                Subject
              </Box>
              <Box mb={3}>{record.Subject.join(', ')}</Box>
            </>
          )}

          {record.Keyword.length > 0 && (
            <>
              <Box color="#666" mb={2} style={{ fontWeight: 'bold' }}>
                Keyword
              </Box>
              <Box mb={3}>{record.Keyword.join(', ')}</Box>
            </>
          )}

          {record.videos.length > 0 && (
            <>
              <Box color="#666" mb={2} style={{ fontWeight: 'bold' }}>
                Videos
              </Box>
              <Flex flexWrap="wrap" mb={3} mx={-3}>
                {record.videos.map((video, i) => (
                  <Box
                    width={[1, 0.5, 0.25]}
                    px={3}
                    mb={3}
                    style={{ maxWidth: '100%' }}
                    key={i}
                  >
                    <video
                      src={video.path}
                      controls={true}
                      style={{ maxWidth: '100%' }}
                    />
                  </Box>
                ))}
              </Flex>
            </>
          )}

          {record.audios.length > 0 && (
            <>
              <Box color="#666" mb={2} style={{ fontWeight: 'bold' }}>
                Audio
              </Box>
              <Flex flexWrap="wrap" mb={3} mx={-3}>
                {record.audios.map((audio, i) => (
                  <Box
                    width={[1, 0.5, 0.25]}
                    px={3}
                    mb={3}
                    style={{ maxWidth: '100%' }}
                    key={i}
                  >
                    <audio
                      src={audio.path}
                      controls={true}
                      style={{ maxWidth: '100%' }}
                    />
                  </Box>
                ))}
              </Flex>
            </>
          )}
        </>
      ) : (
        <Box>No record found at this URL: {slug}</Box>
      )}
    </Box>
  )
}

const mapStateToProps = (state: AppState) => ({
  allTours: state.entities.tours.byId,
  recordsById: state.entities.records.byId,
  recordsBySlug: state.entities.records.bySlug,
  isLoading: state.entities.records.isLoading,
})

const mapDispatchToProps = {
  getRecords: getRecordsIfNeeded,
  getToursIfNeeded,
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Record))
