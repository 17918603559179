// UI
export const TOGGLE_SIDE_DRAWER = 'TOGGLE_SIDE_DRAWER'
export const SET_AUDIO = 'SET_AUDIO'
export const SET_TOUR_STARTED = 'SET_TOUR_STARTED'
export const OPEN_MAP = 'OPEN_MAP'
export const TOGGLE_SEARCH = 'TOGGLE_SEARCH'
export const SET_STOP_MODE = 'SET_STOP_MODE'
export const ADD_NEW_ERROR = 'ADD_NEW_ERROR'
export const CLEAR_ALL_ERRORS = 'CLEAR_ALL_ERRORS'
export const SET_CMS_RECORDS_SEARCH_TERM = 'SET_CMS_RECORDS_SEARCH_TERM'
export const SET_UI_IS_LOADING = 'SET_UI_IS_LOADING'

// CONTENT - ABOUT US
export const GET_ABOUT_US_CONTENT_FAILURE = 'GET_ABOUT_US_CONTENT_FAILURE'
export const GET_ABOUT_US_CONTENT_REQUEST = 'GET_ABOUT_US_CONTENT_REQUEST'
export const GET_ABOUT_US_CONTENT_SUCCESS = 'GET_ABOUT_US_CONTENT_SUCCESS'

// Tours
export const GET_TOURS_REQUEST = 'GET_TOURS_REQUEST'
export const GET_TOURS_SUCCESS = 'GET_TOURS_SUCCESS'
export const GET_TOURS_FAILURE = 'GET_TOURS_FAILURE'
export const SET_INITIAL_STOP = 'SET_INITIAL_STOP'
export const SET_RECORD_ID = 'SET_RECORD_ID'
export const SET_LANDING_TOURS = 'SET_LANDING_TOURS'

// Stops
export const GET_STOPS_FOR_TOUR_REQUEST = 'GET_STOPS_FOR_TOUR_REQUEST'
export const GET_STOPS_FOR_TOUR_SUCCESS = 'GET_STOPS_FOR_TOUR_SUCCESS'
export const GET_STOPS_FOR_TOUR_FAILURE = 'GET_STOPS_FOR_TOUR_FAILURE'
export const GET_KEYWORDS_REQUEST = 'GET_KEYWORDS_REQUEST'
export const GET_KEYWORDS_SUCCESS = 'GET_KEYWORDS_SUCCESS'
export const GET_KEYWORDS_FAILURE = 'GET_KEYWORDS_FAILURE'
export const GET_STOPS_BY_KEYWORD_REQUEST = 'GET_STOPS_BY_KEYWORD_REQUEST'
export const GET_STOPS_BY_KEYWORD_SUCCESS = 'GET_STOPS_BY_KEYWORD_SUCCESS'
export const GET_STOPS_BY_KEYWORD_FAILURE = 'GET_STOPS_BY_KEYWORD_FAILURE'

// RECORDS
export const GET_RECORDS_REQUEST = 'GET_RECORDS_REQUEST'
export const GET_RECORDS_SUCCESS = 'GET_RECORDS_SUCCESS'
export const GET_RECORDS_FAILURE = 'GET_RECORDS_FAILURE'

// FEATURED TOURS
export const GET_FEATURED_TOURS_REQUEST = 'GET_FEATURED_TOURS_REQUEST'
export const GET_FEATURED_TOURS_SUCCESS = 'GET_FEATURED_TOURS_SUCCESS'
export const GET_FEATURED_TOURS_FAILURE = 'GET_FEATURED_TOURS_FAILURE'
export const SAVE_FEATURED_TOURS_FAILURE = 'SAVE_FEATURED_TOURS_FAILURE'
export const SAVE_FEATURED_TOURS_REQUEST = 'SAVE_FEATURED_TOURS_REQUEST'
export const SAVE_FEATURED_TOURS_SUCCESS = 'SAVE_FEATURED_TOURS_SUCCESS'

// SOCIAL LINKS
export const GET_SOCIAL_LINKS_REQUEST = 'GET_SOCIAL_LINKS_REQUEST'
export const GET_SOCIAL_LINKS_SUCCESS = 'GET_SOCIAL_LINKS_SUCCESS'
export const GET_SOCIAL_LINKS_FAILURE = 'GET_SOCIAL_LINKS_FAILURE'
export const SAVE_SOCIAL_LINKS_FAILURE = 'SAVE_SOCIAL_LINKS_FAILURE'
export const SAVE_SOCIAL_LINKS_REQUEST = 'SAVE_SOCIAL_LINKS_REQUEST'
export const SAVE_SOCIAL_LINKS_SUCCESS = 'SAVE_SOCIAL_LINKS_SUCCESS'

// CMS CONTENT
export const CMS_GET_ABOUT_US_CONTENT_FAILURE = 'GET_ABOUT_US_CONTENT_FAILURE'
export const CMS_GET_ABOUT_US_CONTENT_REQUEST = 'GET_ABOUT_US_CONTENT_REQUEST'
export const CMS_GET_ABOUT_US_CONTENT_SUCCESS = 'GET_ABOUT_US_CONTENT_SUCCESS'
export const CMS_POST_ABOUT_US_CONTENT_FAILURE = 'POST_ABOUT_US_CONTENT_FAILURE'
export const CMS_POST_ABOUT_US_CONTENT_REQUEST = 'POST_ABOUT_US_CONTENT_REQUEST'
export const CMS_POST_ABOUT_US_CONTENT_SUCCESS = 'POST_ABOUT_US_CONTENT_SUCCESS'

export const CMS_GET_STAFF_MEMBER_FAILURE = 'GET_ABOUT_US_CONTENT_FAILURE'
export const CMS_GET_STAFF_MEMBER_REQUEST = 'GET_ABOUT_US_CONTENT_REQUEST'
export const CMS_GET_STAFF_MEMBER_SUCCESS = 'GET_ABOUT_US_CONTENT_SUCCESS'
export const CMS_POST_STAFF_MEMBER_FAILURE = 'POST_ABOUT_US_CONTENT_FAILURE'
export const CMS_POST_STAFF_MEMBER_REQUEST = 'POST_ABOUT_US_CONTENT_REQUEST'
export const CMS_POST_STAFF_MEMBER_SUCCESS = 'POST_ABOUT_US_CONTENT_SUCCESS'
export const CMS_DELETE_STAFF_MEMBER_FAILURE = 'POST_ABOUT_US_CONTENT_FAILURE'
export const CMS_DELETE_STAFF_MEMBER_REQUEST = 'POST_ABOUT_US_CONTENT_REQUEST'
export const CMS_DELETE_STAFF_MEMBER_SUCCESS = 'POST_ABOUT_US_CONTENT_SUCCESS'

// CMS RECORDS
export const CMS_GET_RECORDS_REQUEST = 'CMS_GET_RECORDS_REQUEST'
export const CMS_GET_RECORDS_SUCCESS = 'CMS_GET_RECORDS_SUCCESS'
export const CMS_GET_RECORDS_FAILURE = 'CMS_GET_RECORDS_FAILURE'
export const CMS_SET_EDITED_RECORD = 'CMS_SET_EDITED_RECORD'
export const CMS_SAVE_RECORD_REQUEST = 'CMS_SAVE_RECORD_REQUEST'
export const CMS_SAVE_RECORD_SUCCESS = 'CMS_SAVE_RECORD_SUCCESS'
export const CMS_SAVE_RECORD_FAILURE = 'CMS_SAVE_RECORD_FAILURE'
export const CMS_DELETE_RECORD_REQUEST = 'CMS_DELETE_RECORD_REQUEST'
export const CMS_DELETE_RECORD_SUCCESS = 'CMS_DELETE_RECORD_SUCCESS'
export const CMS_DELETE_RECORD_FAILURE = 'CMS_DELETE_RECORD_FAILURE'
export const CMS_SET_SELECTED_RECORD_TYPE = 'CMS_SET_SELECTED_RECORD_TYPE'

// CMS TOURS
export const CMS_GET_TOURS_REQUEST = 'CMS_GET_TOURS_REQUEST'
export const CMS_GET_TOURS_SUCCESS = 'CMS_GET_TOURS_SUCCESS'
export const CMS_GET_TOURS_FAILURE = 'CMS_GET_TOURS_FAILURE'
export const CMS_SET_EDITED_TOUR = 'CMS_SET_EDITED_TOUR'
export const CMS_SAVE_TOUR_REQUEST = 'CMS_SAVE_TOUR_REQUEST'
export const CMS_SAVE_TOUR_SUCCESS = 'CMS_SAVE_TOUR_SUCCESS'
export const CMS_SAVE_TOUR_FAILURE = 'CMS_SAVE_TOUR_FAILURE'
export const CMS_DELETE_TOUR_REQUEST = 'CMS_DELETE_TOUR_REQUEST'
export const CMS_DELETE_TOUR_SUCCESS = 'CMS_DELETE_TOUR_SUCCESS'
export const CMS_DELETE_TOUR_FAILURE = 'CMS_DELETE_TOUR_FAILURE'

// CMS STOPS
export const CMS_GET_STOPS_REQUEST = 'CMS_GET_STOPS_REQUEST'
export const CMS_GET_STOPS_SUCCESS = 'CMS_GET_STOPS_SUCCESS'
export const CMS_GET_STOPS_FAILURE = 'CMS_GET_STOPS_FAILURE'
export const CMS_SET_EDITED_STOPS = 'CMS_SET_EDITED_STOPS'
export const CMS_SAVE_STOP_REQUEST = 'CMS_SAVE_STOP_REQUEST'
export const CMS_SAVE_STOP_SUCCESS = 'CMS_SAVE_STOP_SUCCESS'
export const CMS_SAVE_STOP_FAILURE = 'CMS_SAVE_STOP_FAILURE'
export const CMS_DELETE_STOP_REQUEST = 'CMS_DELETE_STOP_REQUEST'
export const CMS_DELETE_STOP_SUCCESS = 'CMS_DELETE_STOP_SUCCESS'
export const CMS_DELETE_STOP_FAILURE = 'CMS_DELETE_STOP_FAILURE'

// CMS QR CODES
export const CMS_GET_QR_CODES_REQUEST = 'CMS_GET_QR_CODES_REQUEST'
export const CMS_GET_QR_CODES_SUCCESS = 'CMS_GET_QR_CODES_SUCCESS'
export const CMS_GET_QR_CODES_FAILURE = 'CMS_GET_QR_CODES_FAILURE'
export const CMS_SET_EDITED_QR_CODE = 'CMS_SET_EDITED_QR_CODE'
export const CMS_SAVE_QR_CODE_FAILURE = 'CMS_SAVE_QR_CODE_FAILURE'
export const CMS_SAVE_QR_CODE_REQUEST = 'CMS_SAVE_QR_CODE_REQUEST'
export const CMS_SAVE_QR_CODE_SUCCESS = 'CMS_SAVE_QR_CODE_SUCCESS'
export const CMS_DELETE_QR_CODE_REQUEST = 'CMS_DELETE_QR_CODE_REQUEST'
export const CMS_DELETE_QR_CODE_SUCCESS = 'CMS_DELETE_QR_CODE_SUCCESS'
export const CMS_DELETE_QR_CODE_FAILURE = 'CMS_DELETE_QR_CODE_FAILURE'

// CMS FEATURED TOURS
export const CMS_GET_FEATURED_TOURS_REQUEST = 'CMS_GET_FEATURED_TOURS_REQUEST'
export const CMS_GET_FEATURED_TOURS_SUCCESS = 'CMS_GET_FEATURED_TOURS_SUCCESS'
export const CMS_GET_FEATURED_TOURS_FAILURE = 'CMS_GET_FEATURED_TOURS_FAILURE'
export const CMS_SAVE_FEATURED_TOURS_FAILURE = 'CMS_SAVE_FEATURED_TOURS_FAILURE'
export const CMS_SAVE_FEATURED_TOURS_REQUEST = 'CMS_SAVE_FEATURED_TOURS_REQUEST'
export const CMS_SAVE_FEATURED_TOURS_SUCCESS = 'CMS_SAVE_FEATURED_TOURS_SUCCESS'

// CMS SOCIAL LINKS
export const CMS_GET_SOCIAL_LINKS_REQUEST = 'CMS_GET_SOCIAL_LINKS_REQUEST'
export const CMS_GET_SOCIAL_LINKS_SUCCESS = 'CMS_GET_SOCIAL_LINKS_SUCCESS'
export const CMS_GET_SOCIAL_LINKS_FAILURE = 'CMS_GET_SOCIAL_LINKS_FAILURE'
export const CMS_SAVE_SOCIAL_LINKS_FAILURE = 'CMS_SAVE_SOCIAL_LINKS_FAILURE'
export const CMS_SAVE_SOCIAL_LINKS_REQUEST = 'CMS_SAVE_SOCIAL_LINKS_REQUEST'
export const CMS_SAVE_SOCIAL_LINKS_SUCCESS = 'CMS_SAVE_SOCIAL_LINKS_SUCCESS'

// CMS USER SESSION
export const LOG_IN = 'LOG_IN'
export const LOG_OUT = 'LOG_OUT'
