import ReactGA from 'react-ga'
import config from './config'

//const isDev = process.env.NODE_ENV === 'development'
const isDev = false

ReactGA.initialize(config.GOOGLE_ANALYTICS_TRACKING_ID, {
  debug: isDev,
})

// Required. A top level category for these events. E.g. 'User', 'Navigation', 'App Editing', etc.
export enum EventCategory {
  Tour = 'Tour',
  Stop = 'Stop',
  Record = 'Record',
  Map = 'Map',
  Search = 'Search',
  Nav = 'Nav',
  Audio = 'Audio',
  Images = 'Images',
  Donation = 'Donation',
  LandingPage = 'LandingPage',
}

// Required. A description of the behaviour. E.g. 'Clicked Delete', 'Added a component', 'Deleted account', etc.
export enum EventAction {
  IntroViewed = 'IntroViewed',
  Started = 'Started',
  OutroViewed = 'OutroViewed',
  CompleteTourButtonClicked = 'CompleteTourButtonClicked',
  GetDirectionsLinkClicked = 'GetDirectionsLinkClicked',
  CitationCollapseOpened = 'CitationCollapseOpened',
  GoToNextStopClicked = 'GoToNextStopClicked',
  FullRecordAndCitationOpened = 'FullRecordAndCitationOpened',
  CarouselPaged = 'CarouselPaged',
  TourLandingViewMapButtonClicked = 'TourLandingViewMapButtonClicked',
  TourSummaryViewMapButtonClicked = 'TourSummaryViewMapButtonClicked',
  ArriveButtonClicked = 'ArriveButtonClicked',
  ButtonClicked = 'ButtonClicked',
  SearchClicked = 'SearchClicked',
  SearchExecuted = 'SearchExecuted',
  SearchTermsEntered = 'SearchTermsEntered',
  NavOpened = 'NavOpened',
  NavItemClicked = 'NavItemClicked',
  PlayToggle = 'PlayToggle',
  PauseToggle = 'PauseToggle',
  Back10Seconds = 'Back10Seconds',
  Forward10Seconds = 'Forward10Seconds',
  LightboxOpened = 'LightboxOpened',
  ContinueClicked = 'ContinueClicked',
  TourPicked = 'TourPicked',
}

export const pageview = ReactGA.pageview
export const modalview = ReactGA.modalview

export interface IEventArgs {
  category: EventCategory
  action: EventAction
  label?: string
  value?: number
  nonInteraction?: boolean
  transport?: 'beacon' | 'xhr' | 'image' | undefined
}
export const event = (args: IEventArgs) => {
  ReactGA.event(args)
}

export const timing = ReactGA.timing
export const outboundLink = ReactGA.outboundLink
export const exception = ReactGA.exception
