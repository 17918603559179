import { IRecord } from '../interfaces'
import uuid from 'uuid'

export default (): Partial<IRecord> => {
  return {
    id: uuid.v4(),
    Title: '',
    slug: '',
    Creator: '',
    Address: '',
    Author: '',
    Description: '',
    Source: [],
    Date: '',
    Contributor: [],
    RelationLink: '',
    RelationText: '',
    Type: '',
    Coverage: '',
    Rights: '',
    Subject: [],
    Keyword: [],
    Tours: [],
    Notes: '',
    audios: [],
    videos: [],
    images: [],
  }
}
