import React, { MouseEvent, useEffect, useRef } from 'react'
import { Box } from 'rebass'
import mapboxgl from 'mapbox-gl'

import { Close } from '@material-ui/icons'

interface IWalkingPathMapProps {
  mapUrl: string
  onClose: () => void
  zoomLevel: number
}
const WalkingPathMap: React.FC<IWalkingPathMapProps> = ({
  mapUrl,
  onClose,
  zoomLevel,
}) => {
  const mapContainer = useRef(null)

  useEffect(() => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN || ''

    // tslint:disable-next-line:no-unused-expression
    new mapboxgl.Map({
      container: mapContainer.current || '',
      style: mapUrl,
      zoom: zoomLevel,
      center: [-86.7816, 36.1627],
    })
  }, [zoomLevel, mapUrl])

  const stopPropagation = (e: MouseEvent) => {
    e.stopPropagation()
  }

  return (
    <>
      <Box
        onClick={onClose}
        sx={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 6,
          overflow: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          width={[0.9, 0.75, 0.5]}
          sx={{
            position: 'relative',
            borderRadius: 2,
            backgroundColor: '#ffffff',
            boxShadow: '0 6px 12px 0 #666666',
          }}
          onClick={stopPropagation}
        >
          <Box ref={mapContainer} sx={{ height: '50vh', width: '100%' }} />

          <Box
            sx={{ position: 'absolute', top: 0, right: 0 }}
            onClick={onClose}
          >
            <Close fontSize="large" />
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default WalkingPathMap
