import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { Close } from '@material-ui/icons'
import { toggleSideDrawer } from '../../actions/ui'
import { Box, Flex, Link as RebassLink } from 'rebass'
import { Link } from 'react-router-dom'
import { AppState } from '../../reducers/rootReducer'
import { INavLink } from '../../interfaces'
import { event, EventAction, EventCategory } from '../../tracking'

interface ISideDrawerProps {
  toggleSideDrawer: () => void
  links: INavLink[]
}
const SideDrawer: React.FC<ISideDrawerProps> = props => {
  const sideDrawerRef = useRef<HTMLDivElement>(null)
  const handleOutsideClick = (e: MouseEvent) => {
    if (
      sideDrawerRef.current &&
      !sideDrawerRef.current.contains(e.target as Node)
    ) {
      props.toggleSideDrawer()
    }
  }
  useEffect(() => {
    document.addEventListener('mouseup', handleOutsideClick)
    return () => {
      document.removeEventListener('mouseup', handleOutsideClick)
    }
  })
  const handleLinkClick = (toPath: string) => () => {
    event({
      category: EventCategory.Nav,
      action: EventAction.NavItemClicked,
      label: toPath,
    })

    props.toggleSideDrawer()
  }
  const renderLink = (link: INavLink) => (
    <Box
      key={link.name}
      mb={4}
      sx={{
        textDecoration: 'none',
        cursor: 'pointer',
        display: 'block',
        color: 'black',
        fontWeight: 'bold',
        fontSize: 20,
      }}
    >
      {link.external ? (
        <RebassLink
          onClick={handleLinkClick(link.path)}
          href={link.path}
          target="_blank"
          variant="navLink"
        >
          {link.name}
        </RebassLink>
      ) : (
        // ts compiler complains that `to` is not a property of RebassLink
        // @ts-ignore
        <RebassLink
          onClick={handleLinkClick(link.path)}
          // @ts-ignore
          to={link.path}
          // @ts-ignore
          as={Link}
          variant="navLink"
        >
          {link.name}
        </RebassLink>
      )}
    </Box>
  )

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 4,
        overflow: 'auto',
        justifyContent: 'safe center',
      }}
    >
      <Flex
        ref={sideDrawerRef}
        flexDirection={['column']}
        bg="white"
        px={[4]}
        pt={[3]}
        pb={2}
        as="nav"
        sx={{
          position: 'absolute',
          top: 2,
          left: 2,
          zIndex: 4,
          boxShadow: '0 6px 12px 0 #666666',
          borderRadius: 4,
        }}
      >
        <Box mb={2} width={[1, 0]} sx={{ position: 'relative', left: -1 }}>
          <Close onClick={props.toggleSideDrawer} fontSize="large" />
        </Box>
        {props.links.map(renderLink)}
      </Flex>
    </Box>
  )
}

const mapStateToProps = (state: AppState) => ({ links: state.ui.links })

const mapDispatchToProps = {
  toggleSideDrawer,
}

export default connect(mapStateToProps, mapDispatchToProps)(SideDrawer)
