import {
  TOGGLE_SIDE_DRAWER,
  OPEN_MAP,
  TOGGLE_SEARCH,
  SET_STOP_MODE,
  ADD_NEW_ERROR,
  CLEAR_ALL_ERRORS,
  SET_CMS_RECORDS_SEARCH_TERM,
  SET_UI_IS_LOADING,
  SET_AUDIO,
  SET_TOUR_STARTED,
} from '../constants/actionTypes'
import { IUIState } from '../interfaces'
import { AnyAction } from 'redux'

const initialState: IUIState = {
  isSideDrawerOpen: false,
  isAudioPlaying: false,
  isTourStarted: false,
  isMapOpen: false,
  isSearchOpen: false,
  isStopMode: false,
  links: [
    {
      name: 'Home',
      path: '/',
    },
    {
      name: 'Tours',
      path: '/tours',
    },
    {
      name: 'Records',
      path: '/records',
    },
    {
      name: 'Lesson Plans',
      path: '/lesson-plans',
    },
    {
      name: 'FAQ',
      path: '/faq',
    },
    {
      name: 'About Us',
      path: '/about-us',
    },
    {
      name: 'Support Us',
      //path: 'http://gf.me/u/wmv2vf',
      path: 'https://donorbox.org/support-nashville-sites',
      external: true,
    },
    {
      name: 'Merchandise',
      //path: 'http://gf.me/u/wmv2vf',
      path: 'https://www.bonfire.com/store/nashville-sites/',
      external: true,
    },
    {
      name: 'Our Sponsors',
      path: '/our-sponsors',
    },
  ],
  errors: [],
  cmsRecordsSearchTerm: '',
  isLoading: false,
}

const uiReducer = (state = initialState, action: AnyAction): IUIState => {
  switch (action.type) {
    case TOGGLE_SIDE_DRAWER:
      return { ...state, isSideDrawerOpen: !state.isSideDrawerOpen }
    case SET_AUDIO:
      return { ...state, isAudioPlaying: action.isAudioPlaying }
    case SET_TOUR_STARTED:
      return { ...state, isTourStarted: action.isTourStarted }
    case OPEN_MAP:
      return { ...state, isMapOpen: action.isMapOpen }
    case TOGGLE_SEARCH:
      return { ...state, isSearchOpen: !state.isSearchOpen }
    case SET_STOP_MODE:
      return { ...state, isStopMode: action.isStopMode }
    case ADD_NEW_ERROR:
      return { ...state, errors: [...state.errors, action.error] }
    case CLEAR_ALL_ERRORS:
      return { ...state, errors: [] }
    case SET_CMS_RECORDS_SEARCH_TERM:
      return { ...state, cmsRecordsSearchTerm: action.searchTerm }
    case SET_UI_IS_LOADING:
      return { ...state, isLoading: action.isLoading }
    default:
      return state
  }
}

export default uiReducer
