import {
  TOGGLE_SIDE_DRAWER,
  SET_AUDIO,
  OPEN_MAP,
  TOGGLE_SEARCH,
  SET_STOP_MODE,
  CLEAR_ALL_ERRORS,
  ADD_NEW_ERROR,
  SET_CMS_RECORDS_SEARCH_TERM,
  SET_UI_IS_LOADING,
  SET_TOUR_STARTED,
} from '../constants/actionTypes'
import { AxiosError } from 'axios'

export const toggleSideDrawer = () => ({
  type: TOGGLE_SIDE_DRAWER,
})

export const setAudio = (bool: boolean) => ({
  type: SET_AUDIO,
  isAudioPlaying: bool,
})

export const setTourStarted = (bool: boolean) => ({
  type: SET_TOUR_STARTED,
  isTourStarted: bool,
})

export const openMap = (isMapOpen: boolean) => ({
  type: OPEN_MAP,
  isMapOpen,
})

export const toggleSearch = () => ({
  type: TOGGLE_SEARCH,
})

export const setStopMode = (bool: boolean) => ({
  type: SET_STOP_MODE,
  isStopMode: bool,
})

export const addNewError = (error: Error | AxiosError) => ({
  type: ADD_NEW_ERROR,
  error,
})

export const clearAllErrors = () => ({
  type: CLEAR_ALL_ERRORS,
})

export const setCmsRecordsSearchTerm = (searchTerm: string) => ({
  type: SET_CMS_RECORDS_SEARCH_TERM,
  searchTerm,
})

// This is basically a isLoading override that can be used by anything
export const setUiIsLoading = (isLoading: boolean) => ({
  type: SET_UI_IS_LOADING,
  isLoading,
})
