import React, { useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import styled from 'styled-components'
import { Box, Flex, Heading, Image, Link, Text } from 'rebass'

import Button from '../../common/Button'
import withViewMore from '../../common/withViewMore'
import WalkingPathMap from '../../common/WalkingPathMap'
import { event, EventAction, EventCategory } from '../../../tracking'

import { ITour } from '../../../interfaces'

import stopwatch from '../../../assets/icons/ic_timer.svg'
import walking from '../../../assets/icons/ic_directions_walk.svg'
import landscape from '../../../assets/icons/ic_siteamount.svg'
import location from '../../../assets/icons/ic-tour-marker.svg'

import { notNull } from '../../../util'
import { setAudio, setTourStarted } from '../../../actions/ui'
import { AppState } from '../../../reducers/rootReducer'
import { connect } from 'react-redux'

const StyledText = styled(Text)`
  cursor: pointer;
  color: #2e4ea5;
`

interface ITourLandingPageProps extends RouteComponentProps {
  tour: ITour
  onStartTour: (tourToStart: ITour) => void
  onStartVirtualTour: (tourToStart: ITour) => void
  onGetDirections: () => void
  setAudio: (newState: boolean) => void
  isTourStarted: boolean
  setTourStarted: (newState: boolean) => void
}

const TourLandingPage = ({
  tour,
  onStartTour,
  onStartVirtualTour,
  onGetDirections,
  setAudio,
  isTourStarted,
  setTourStarted,
}: ITourLandingPageProps) => {
  const [showWalkingPath, setShowWalkingPath] = useState(false)

  // TODO remove this goofy ass null check
  // data on zoom level prop expected to be sparse
  const tourMapZoomLevel = notNull(tour.tourMapZoomLevel)
    ? tour.tourMapZoomLevel
    : 12

  const tourTitle = tour.title

  useEffect(() => {
    event({
      category: EventCategory.Tour,
      action: EventAction.IntroViewed,
      label: tourTitle,
    })
  }, [tour.title, tourTitle])

  if (!tour) {
    return <div>No tour found</div>
  }

  const handleTakeTour = () => {
    setAudio(true)
    setTourStarted(true)
  }

  const handleStartTour = () => {
    onStartTour(tour)
  }

  const handleTakeVirtualTour = () => {
    setAudio(true)
    setTourStarted(true)
  }

  const handleStartVirtualTour = () => {
    onStartVirtualTour(tour)
  }

  const handleGetDirectionsClick = () => {
    onGetDirections()
  }

  const Copy = () => (
    <Box>
      <Text as="p" dangerouslySetInnerHTML={{ __html: tour.intro }} />
    </Box>
  )
  const CopyWithViewMore = withViewMore(Copy)

  const handleViewMapClick = () => {
    event({
      category: EventCategory.Tour,
      action: EventAction.TourLandingViewMapButtonClicked,
      label: tour.title,
    })
    setShowWalkingPath(true)
  }

  return (
    <>
      {showWalkingPath && (
        <WalkingPathMap
          mapUrl={tour.map}
          zoomLevel={tourMapZoomLevel}
          onClose={() => {
            setShowWalkingPath(false)
          }}
        />
      )}

      <Flex
        pb={[4]}
        as="main"
        flexDirection="column"
        alignItems={['center', 'start']}
        width={[1]}
      >
        <Flex mt={[2]} flexDirection={['column', 'row']}>
          {isTourStarted ? (
            <Button width={[168]} onClick={handleStartTour}>
              Go to First Stop
            </Button>
          ) : (
            <Button width={[168]} onClick={handleTakeTour}>
              Take Tour
            </Button>
          )}
          <Text
            my={[3, 0]}
            mx={[0, 3]}
            textAlign={['center']}
            alignSelf={['center']}
            display={['block']}
          >
            or
          </Text>
          {isTourStarted ? (
            <Button
              variant="outline"
              width={[168]}
              onClick={handleStartVirtualTour}
            >
              Go to Virtual Stop
            </Button>
          ) : (
            <Button
              variant="outline"
              width={[168]}
              onClick={handleTakeVirtualTour}
            >
              Take Tour Virtually
            </Button>
          )}
        </Flex>

        <Box mt={[3]} mb={[4]} onClick={handleGetDirectionsClick}>
          <StyledText fontWeight="bold">Get Directions</StyledText>
        </Box>

        <Flex px={[3, 0]} width={[1]} flexDirection={['column']}>
          <Heading fontSize={[4]}>{tour.title}</Heading>
          <Flex
            my={[3]}
            alignItems="center"
            justifyContent={['space-between', 'start']}
          >
            <Flex mr={[0, 3]} alignItems="center">
              <Image src={stopwatch} width={[15, 13]} mr={2} />
              <Text fontSize={[1]}>
                {tour.duration > 1
                  ? tour.duration + ' hours'
                  : tour.duration + ' hour'}
              </Text>
            </Flex>
            <Flex mr={[0, 3]} alignItems="center">
              <Image src={walking} width={[13, 11]} mr={2} />
              <Text fontSize={[1]}>
                {tour.distance > 1
                  ? tour.distance + ' miles'
                  : tour.distance + ' mile'}
              </Text>
            </Flex>
            <Flex mr={[0, 3]} alignItems="center">
              <Image src={landscape} width={[14, 12]} mr={2} />
              <Text fontSize={[1]}>
                {tour.stopIds.length > 1
                  ? tour.stopIds.length + ' sites'
                  : tour.stopIds.length + ' site'}
              </Text>
            </Flex>
            <Flex mr={[0, 3]} alignItems="center">
              <Image src={location} width={[0, 11]} mr={2} />
              <Text fontSize={[1]}>
                <Link
                  style={{ fontWeight: 'bold', cursor: 'pointer' }}
                  onClick={handleViewMapClick}
                >
                  View Map
                </Link>
              </Text>
            </Flex>
          </Flex>

          <CopyWithViewMore />
        </Flex>
      </Flex>
    </>
  )
}
const mapStateToProps = (state: AppState) => ({
  isTourStarted: state.ui.isTourStarted,
})

const mapDispatchToProps = {
  setAudio,
  setTourStarted,
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TourLandingPage)
)
