import React, { ChangeEvent } from 'react'
import { Box, Flex, Heading, Image } from 'rebass'
import { IQRCode } from '../../../interfaces'
import { MenuItem, Select, TextField } from '@material-ui/core'
import Button from '../../common/Button'
import { isQRCode } from '../helpers/validators'

import RequiredIndicator from '../common/RequiredIndicator'

interface IAdminQRCode {
  qrCode: Partial<IQRCode>
  onDelete: () => void
  onSave: () => void
  onCancel: () => void
  onQRCodeUpdate: (qrCode: Partial<IQRCode>) => void
}
const AdminQRCode: React.FC<IAdminQRCode> = ({
  qrCode,
  onDelete,
  onSave,
  onCancel,
  onQRCodeUpdate,
}) => {
  const statusOptions = [
    { value: 'OK', label: 'OK' },
    { value: 'Sign Missing/Stolen', label: 'Sign Missing/Stolen' },
    { value: 'Illegible/Marked On', label: 'Illegible/Marked On' },
    { value: 'Damaged/Will Not Scan', label: 'Damaged/Will Not Scan' },
  ]

  const updateQRCode = (field: Partial<IQRCode>) => {
    onQRCodeUpdate(Object.assign(qrCode, field))
  }

  const changeStringField = (fieldName: keyof IQRCode) => (
    e: ChangeEvent<{ value: unknown }>
  ) => {
    updateQRCode({ [fieldName]: e.target.value as string })
  }

  return (
    <>
      {qrCode.codeImgUrl && (
        <Image
          sx={{ border: 'solid 1px black' }}
          src={qrCode.codeImgUrl}
          width={200}
        />
      )}

      <Box mt={3} width={[2 / 3, 1 / 2]}>
        {qrCode.codeURL && (
          <>
            <Heading fontSize={2} fontWeight={600} mt={4} mb={1}>
              QR Code URL
            </Heading>
            <Box>{qrCode.codeURL}</Box>
          </>
        )}

        <Flex mt={4} mb={1}>
          <Heading fontSize={2} fontWeight={600}>
            Redirect URL
          </Heading>
          <RequiredIndicator />
        </Flex>

        <TextField
          margin="dense"
          variant="outlined"
          fullWidth={true}
          value={qrCode.redirectUrl || ''}
          onChange={changeStringField('redirectUrl')}
        />

        <Flex mt={3}>
          <Heading fontSize={2} fontWeight={600}>
            Location Name
          </Heading>
          <RequiredIndicator />
        </Flex>

        <TextField
          margin="dense"
          variant="outlined"
          fullWidth={true}
          value={qrCode.locationName || ''}
          onChange={changeStringField('locationName')}
        />

        <Flex mt={3}>
          <Heading fontSize={2} fontWeight={600}>
            Address
          </Heading>
        </Flex>

        <TextField
          margin="dense"
          variant="outlined"
          fullWidth={true}
          value={qrCode.address || ''}
          onChange={changeStringField('address')}
        />

        <Flex mt={3}>
          <Heading fontSize={2} fontWeight={600}>
            City
          </Heading>
        </Flex>

        <TextField
          margin="dense"
          variant="outlined"
          fullWidth={true}
          value={qrCode.city || ''}
          onChange={changeStringField('city')}
        />

        <Flex mt={3}>
          <Heading fontSize={2} fontWeight={600}>
            State
          </Heading>
        </Flex>

        <TextField
          margin="dense"
          variant="outlined"
          fullWidth={true}
          value={qrCode.state || ''}
          onChange={changeStringField('state')}
        />

        <Flex mt={3}>
          <Heading fontSize={2} fontWeight={600}>
            Zip
          </Heading>
        </Flex>

        <TextField
          margin="dense"
          variant="outlined"
          fullWidth={true}
          value={qrCode.zip || ''}
          onChange={changeStringField('zip')}
        />

        <Flex mt={3}>
          <Heading fontSize={2} fontWeight={600}>
            Tour Description
          </Heading>
          <RequiredIndicator />
        </Flex>

        <TextField
          multiline={true}
          rows={5}
          margin="dense"
          variant="outlined"
          fullWidth={true}
          value={qrCode.locationDescription || ''}
          onChange={changeStringField('locationDescription')}
        />

        <Flex mt={3}>
          <Heading fontSize={2} fontWeight={600}>
            Status
          </Heading>
        </Flex>

        <Select
          margin="dense"
          variant="outlined"
          fullWidth={true}
          value={qrCode.status}
          onChange={changeStringField('status')}
        >
          <MenuItem value={statusOptions[0].value}>
            {statusOptions[0].label}
          </MenuItem>
          <MenuItem value={statusOptions[1].value}>
            {statusOptions[1].label}
          </MenuItem>
          <MenuItem value={statusOptions[2].value}>
            {statusOptions[2].label}
          </MenuItem>
          <MenuItem value={statusOptions[3].value}>
            {statusOptions[3].label}
          </MenuItem>
        </Select>

        <Flex mt={3}>
          <Heading fontSize={2} fontWeight={600}>
            Notes
          </Heading>
        </Flex>

        <TextField
          multiline={true}
          rows={5}
          margin="dense"
          variant="outlined"
          fullWidth={true}
          value={qrCode.notes || ''}
          onChange={changeStringField('notes')}
        />
      </Box>

      <Flex mt={4} width={[2 / 3, 1 / 2]} justifyContent="space-between">
        <Button color="red" variant="outline" onClick={onDelete} confirm={true}>
          Remove
        </Button>
        <Box>
          <Button onClick={onCancel} variant="outline" color="blue">
            Cancel
          </Button>
          <Button
            ml={3}
            variant="primary"
            onClick={onSave}
            disabled={!isQRCode(qrCode)}
          >
            Save
          </Button>
        </Box>
      </Flex>
    </>
  )
}

export default AdminQRCode
