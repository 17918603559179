import React, { useEffect } from 'react'
import Button from '../../common/Button'
import { IQRCode } from '../../../interfaces'
import { Box, Flex, Heading, Image } from 'rebass'
import { RouteComponentProps, withRouter } from 'react-router'
import { connect } from 'react-redux'
import { AppState } from '../../../reducers/rootReducer'
import { cmsGetQRCodesIfNeeded } from '../../../actions/cms'
import sortBy from 'lodash/sortBy'
import Loading from '../../common/Loading'

interface IAdminQRCodes extends RouteComponentProps {
  qrCodesById: Record<string, IQRCode>
  getQRCodes: () => void
  isLoading: boolean
}
const AdminQRCodes: React.FC<IAdminQRCodes> = ({
  history,
  qrCodesById,
  getQRCodes,
  isLoading,
}) => {
  useEffect(() => {
    getQRCodes()
  }, [getQRCodes])

  const qrCodes: IQRCode[] = sortBy(Object.values(qrCodesById), 'codeLabelName')

  const handleAddCode = () => {
    history.push('/admin/qr-codes/new')
  }

  const handleEdit = (id: string) => () => {
    history.push('/admin/qr-codes/edit/' + id)
  }

  if (isLoading) {
    return <Loading />
  }

  const codeSummary = (code: IQRCode) => {
    return (
      <Box key={code.id} mt={4} sx={{ maxWidth: 300 }}>
        <Flex justifyContent="space-between">
          <Box>
            <Heading fontSize={2} fontWeight={600} mt={2} mb={1}>
              Tour Name
            </Heading>
            <Box>{code.locationName}</Box>

            <Heading fontSize={2} fontWeight={600} mt={2} mb={1}>
              Redirect URL
            </Heading>
            <Box>{code.redirectUrl}</Box>
          </Box>
          <Box ml={4} mt={1} width={60}>
            <Image
              sx={{ border: 'solid 1px black', minWidth: 60 }}
              src={code.codeImgUrl}
            />
          </Box>
        </Flex>

        <Button mt={2} variant="primary" onClick={handleEdit(code.id)}>
          Edit
        </Button>
      </Box>
    )
  }

  return (
    <>
      <Button mt={4} variant="primary" onClick={handleAddCode} flex="0 0 auto">
        Add QR Code
      </Button>

      {qrCodes.map(code => codeSummary(code))}
    </>
  )
}

const mapStateToProps = (state: AppState) => ({
  qrCodesById: state.cms.qrCodes.byId,
  isLoading: state.cms.qrCodes.isLoading,
})

const mapDispatchToProps = {
  getQRCodes: cmsGetQRCodesIfNeeded,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminQRCodes))
