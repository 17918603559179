import React, { useCallback, useEffect, useState } from 'react'
import AdminRecord from './AdminRecord'
import { IEditedItem, IRecord } from '../../../interfaces'
import { connect } from 'react-redux'
import { AppState } from '../../../reducers/rootReducer'
import Loading from '../../common/Loading'
import {
  cmsDeleteRecord,
  cmsGetRecordsIfNeeded,
  cmsSaveRecord,
  cmsSetEditedRecord,
} from '../../../actions/cms'
import { RouteComponentProps, withRouter } from 'react-router'
import initNewRecord from '../../../helpers/initNewRecord'
import { Box } from 'rebass'
import { IOption } from '../../../helpers/compileSelectOptions'
import uploadMediaAndSaveRecord from '../../../helpers/uploadMediaAndSaveRecord'
import { isRecord } from '../helpers/validators'

interface IGetRecordData extends RouteComponentProps<{ id: string }> {
  recordsById: { [Key: string]: IRecord }
  recordsBySlug: { [Key: string]: IRecord }
  editedRecord: IEditedItem<IRecord> | null
  getRecords: () => void
  setEditedRecord: (record: IEditedItem<IRecord> | null) => void
  isLoading: boolean
  saveRecord: (x: IRecord, y: boolean) => Promise<void>
  deleteRecord: (record: Partial<IRecord>) => Promise<void>
  options: Partial<Record<keyof IRecord, IOption[]>> | null
  saveCallback?: (x: IRecord | null) => void
  setType?: string // Set the type of record and don't allow modification
}
const GetRecordData: React.FC<IGetRecordData> = ({
  recordsById,
  recordsBySlug,
  editedRecord,
  getRecords,
  setEditedRecord,
  location,
  match,
  isLoading,
  saveRecord,
  deleteRecord,
  history,
  options,
  saveCallback,
  setType,
}) => {
  const [mediaToUpload, setMediaToUpload] = useState()

  let isNew = location.pathname.includes('records/new')
  const isEdit = location.pathname.includes('records/edit')
  const editedId = match.params.id

  // Apologies for this. If we use this component from a non-record path, it's
  // to create a new record.
  if (!isNew && !isEdit) {
    isNew = true
  }

  useEffect(() => {
    // This will get records only if we don't currently have them or if the
    // cache is invalidated.
    getRecords()
  }, [getRecords])

  useEffect(() => {
    const recordToEdit = isNew ? initNewRecord() : recordsById[editedId]

    if (recordToEdit) {
      setEditedRecord({ item: recordToEdit, meta: { isNew } })
    }

    return () => {
      setEditedRecord(null)
    }
  }, [isNew, editedId, recordsById, setEditedRecord])

  const handleRecordUpdate = useCallback(
    (record: Partial<IRecord>) => {
      setEditedRecord({ item: record, meta: { isNew } })
    },
    [setEditedRecord, isNew]
  )

  const onDelete = () => {
    if (isNew) {
      finishedSaving()
    } else if (isEdit && editedRecord) {
      deleteRecord(editedRecord.item).then(() => {
        finishedSaving()
      })
    }
  }

  const onSave = () => {
    uploadMediaAndSaveRecord(editedRecord, mediaToUpload, saveRecord)
      .then(() => finishedSaving())
      .catch(err => {
        console.error('Error saving record:', err)
      })
  }

  const finishedSaving = () => {
    if (saveCallback) {
      if (editedRecord && isRecord(editedRecord.item)) {
        saveCallback(editedRecord.item)
      } else {
        saveCallback(null)
      }
    } else {
      history.push('/admin/records')
    }
  }

  if (isLoading) {
    return <Loading />
  }

  return editedRecord ? (
    <AdminRecord
      options={options}
      record={editedRecord.item}
      onDelete={onDelete}
      onSave={onSave}
      onCancel={finishedSaving}
      onRecordUpdate={handleRecordUpdate}
      onMediaUpload={setMediaToUpload}
      setType={setType}
      recordsBySlug={recordsBySlug}
    />
  ) : (
    <Box>No record found...</Box>
  )
}

const mapStateToProps = (state: AppState) => ({
  recordsById: state.cms.records.byId,
  recordsBySlug: state.cms.records.bySlug,
  editedRecord: state.cms.editedRecord,
  isLoading: state.cms.records.isLoading,
  options: state.cms.records.options,
})

const mapDispatchToProps = {
  setEditedRecord: cmsSetEditedRecord,
  getRecords: cmsGetRecordsIfNeeded,
  saveRecord: cmsSaveRecord,
  deleteRecord: cmsDeleteRecord,
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(GetRecordData))
