import React from 'react'
import { Box, Flex, Text, Link as RebassLink } from 'rebass'
import { event, EventAction, EventCategory } from '../../tracking'

const sendAnalyticsEventOnClick = () => {
  event({
    category: EventCategory.Donation,
    action: EventAction.ButtonClicked,
    label: `TopBanner`,
  })
}

const TopBand: React.FC = () => {
  return (
    <>
      <Box name="topBand" variant="topBand" py={2}>
        <Flex
          flexDirection={['row']}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Text fontWeight={'bold'} display={['none', 'block']}>
            Support Nashville Sites:
          </Text>

          <RebassLink
            ml={[2, 3]}
            onClick={sendAnalyticsEventOnClick}
            variant="goldButton"
            target="_blank"
            href="https://donorbox.org/support-nashville-sites"
          >
            Donate Now!
          </RebassLink>

          <RebassLink
            ml={[2, 3]}
            onClick={sendAnalyticsEventOnClick}
            variant="goldButton"
            target="_blank"
            href="https://www.bonfire.com/store/nashville-sites/"
          >
            Merchandise
          </RebassLink>
        </Flex>
      </Box>
    </>
  )
}

export default TopBand
