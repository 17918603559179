import { createBrowserHistory } from 'history'
import { pageview } from './tracking'
import store from './store'
import { SET_TOUR_STARTED, SET_AUDIO } from './constants/actionTypes'

function resetTourState() {
  store.dispatch({ type: SET_TOUR_STARTED, isTourStarted: false })
  store.dispatch({ type: SET_AUDIO, isAudioPlaying: false })
}

const history = createBrowserHistory()
history.listen(location => {
  pageview(location.pathname)

  // This is being done here to ensure audio is paused and the tour buttons are correct
  // when navigating between tour pages
  if (location.pathname.startsWith('/tours/')) {
    resetTourState()
  }
})

export default history
